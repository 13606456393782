/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PortalConfigContext } from '../../config/portal';
import { setProductImpression } from '../../store/actions/dataLayer';

import { getAssetUrl } from '../../utils/commonHelper';
import { getFSBOUrl } from '../../utils/urlHelpers/fsbo';
import './styles.css';
import AdSlot from '../Ads/AdSlot';

const HeroViewer = (props) => {
  const [imageError, setImageError] = useState(false);
  const [attemptImageLoad, setAttemptImageLoad] = useState(false);
  const [image, setImage] = useState(undefined);

  const context = useContext(PortalConfigContext);

  const handleImageLoaded = () => {
    setAttemptImageLoad(true);
  };

  const handleImageError = () => {
    setImageError(true);
  };

  useEffect(() => {
    // This listener is called when a slot has finished rendering.
    let { images } = props;
    setAttemptImageLoad(true);
    setImage(images[Math.round(Math.random() * (images.length - 1))]);
  }, []);

  const renderStockImage = (lazyLoad, alt) => {
    const { quickSearchBlock } = props;
    return (
      <div className={quickSearchBlock ? 'qs-block hero' : 'hero'}>
        {image && !imageError ? (
          <img
            src={getAssetUrl(image)}
            alt={alt}
            width="1440"
            height="400"
            className="hero-image"
            onLoad={handleImageLoaded}
            onError={handleImageError}
          />
        ) : null}
      </div>
    );
  };

  const renderDefaultHeroAd = (alt) => {
    const currentLocale = props.intl.locale;
    const heroAdDefaultImageUrl = get(
      context,
      `languages.${currentLocale}.heroAdDefaultImageUrl`,
      getFSBOUrl()
    );
    const heroAdDefaultImageSrc = getAssetUrl(
      get(context, `languages.${currentLocale}.heroAdDefaultImageSrc`)
    );
    return (
      <div className="hero">
        {
          <a href={heroAdDefaultImageUrl}>
            <img
              src={heroAdDefaultImageSrc}
              alt={alt}
              width="1440"
              height="500"
              className="hero-image"
            />
          </a>
        }
      </div>
    );
  };

  const renderPremiumFeaturedAd = (ad) => {
    return (
      <AdSlot adParams={{ pfba: ad }} adSlot="pfba" />
    );
  };

  const renderGoogleHeroAd = (ad, alt) => {
    return renderDefaultHeroAd(alt);
  };

  let { lazyLoad, alt, ad, premiumFeaturedAd } = props;

  if (!attemptImageLoad) {
    return (
      <div className={classnames('hero', { 'with-ad': ad && !ad.hideInfobar })}>
        <div className="hero-image"></div>
      </div>
    );
  }

  if (premiumFeaturedAd?.placement) {
    return renderPremiumFeaturedAd(premiumFeaturedAd);
  }

  if (ad) {
    return renderGoogleHeroAd(ad, alt);
  }

  return renderStockImage(lazyLoad, alt);
};

HeroViewer.defaultProps = {
  lazyLoad: false,
  resize: {},
};

HeroViewer.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** Make image visible by default */
  lazyLoad: PropTypes.bool,
  alt: PropTypes.string.isRequired,
  ad: PropTypes.object,
  setProductImpression: PropTypes.func.isRequired,
  /** Resize specifications for the image */
  resize: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number
  }),
  quickSearchBlock: PropTypes.bool,
  premiumFeaturedAd: PropTypes.object,
};

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      setProductImpression
    },
    dispatch
  )
)(injectIntl(HeroViewer));
