import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PortalConfigContext } from '../../config/portal';
import { setProductImpression } from '../../store/actions/dataLayer';
import { getAssetUrl } from '../../utils/commonHelper';
import { getFSBOUrl } from '../../utils/urlHelpers/fsbo';
import AdSlot from '../Ads/AdSlot';

import './styles.css';

const HeroAdViewer = (props) => {
  const context = useContext(PortalConfigContext);

  const renderDefaultHeroAd = (alt) => {
    const currentLocale = props.intl.locale;
    const heroAdDefaultImageUrl = context?.languages?.[currentLocale]?.heroAdDefaultImageUrl ?? getFSBOUrl();
    const heroAdDefaultImageSrc = getAssetUrl(
      context?.languages?.[currentLocale]?.heroAdDefaultImageSrc ?? ''
    );
    return (
      <div className="hero">
        {
          <a href={heroAdDefaultImageUrl}>
            <img
              src={heroAdDefaultImageSrc}
              alt={alt}
              width="1440"
              height="500"
              className="hero-image"
            />
          </a>
        }
      </div>
    );
  };

  const { alt, premiumFeaturedAd } = props;

  if (premiumFeaturedAd.placement) {
    return (
      <AdSlot adParams={{ pfba: premiumFeaturedAd }} adSlot="pfba" />
    );
  }
  return renderDefaultHeroAd(alt);
};

HeroAdViewer.propTypes = {
  alt: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired
  }).isRequired,
  setProductImpression: PropTypes.func.isRequired,
  premiumFeaturedAd: PropTypes.object,
};

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      setProductImpression
    },
    dispatch
  )
)(injectIntl(HeroAdViewer));
